var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"shadow-xl inner-popup-container",staticStyle:{"color":"rgba(255, 255, 255, 0.9)","font-weight":"200","font-size":"17px","height":"auto"}},[_c('div',{staticStyle:{"position":"relative","overflow-y":"scroll","max-height":"90vh","margin-bottom":"10px"}},[_c('div',{staticStyle:{"width":"100%","overflow":"hidden","margin-bottom":"28px"}},[_c('img',{staticStyle:{"width":"22px","float":"left","clear":"both","margin-top":"6px"},attrs:{"src":require("../../assets/calender_icon_white.png")}}),_c('p',{staticStyle:{"margin-left":"13px","margin-top":"6px","float":"left","font-size":"17px","font-weight":"500","color":"rgba(255, 255, 255, 0.7)"}},[_vm._v(" New shift ")]),_c('span',{staticClass:"material-icons",staticStyle:{"cursor":"pointer","float":"right","color":"rgba(255, 255, 255, 0.75)","font-size":"26px","font-weight":"400","margin-top":"2px"},on:{"click":function($event){return _vm.closeAddShiftPopup()}}},[_vm._v(" close ")]),_c('div',{staticStyle:{"border-radius":"3px","float":"right","height":"38px","width":"240px","margin-right":"16px"}},[_c('div',{style:(_vm.popupTypeSelected == 'NewShift' ? `color: rgba(255,255,255,0.7); cursor: pointer;
            border-top-left-radius: 3px; border-bottom-left-radius: 3px; border: 1px solid rgba(255,255,255,0.8);
            padding-top: 7px; font-weight: 500; font-size: 15px; text-align: center;
            width: 50%; float: left; height: 100%;` :
            `cursor: pointer; border-top-left-radius: 3px; border-bottom-left-radius: 3px;
            color: rgba(250,250,250,0.4); padding-top: 7px; font-weight: 500; font-size: 15px;
            text-align: center; width: 50%; float: left; height: 100%; background: rgba(0,0,0,0.2);`),on:{"click":function($event){return _vm.shiftTabSelected('NewShift')}}},[_vm._v(" Shift ")]),_c('div',{style:(_vm.popupTypeSelected == 'NewShiftTemplate' ? `color: rgba(255,255,255,0.7);cursor: pointer;
          border-top-right-radius: 3px; border-bottom-right-radius: 3px; border: 1px solid rgba(255,255,255,0.8);
          padding-top: 7px; font-weight: 500; font-size: 15px; text-align: center;
          width: 50%; float: right; height: 100%;` :
            `cursor: pointer; border-top-right-radius: 3px; border-bottom-right-radius: 3px;
          color: rgba(250,250,250,0.4); padding-top: 7px; font-weight: 500; font-size: 15px;
          text-align: center; width: 50%; float: right; height: 100%; background: rgba(0,0,0,0.2);`),on:{"click":function($event){return _vm.shiftTabSelected('NewShiftTemplate')}}},[_vm._v(" Templates ")])])])]),(_vm.popupTypeSelected == 'NewShift')?_c('add-shift',{on:{"close-add-shift-popup":function($event){return _vm.closeAddShiftPopup()}}}):_vm._e(),(_vm.popupTypeSelected == 'NewShiftTemplate')?_c('add-shift-template',{on:{"hide-add-new-job-clicked":function($event){return _vm.closeAddShiftTemplatePopup()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }