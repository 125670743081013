
import { Component, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';

@Component({
  components: {
  AddShift: () => getComponent('schedule/AddShift'),
  AddShiftTemplate: () => getComponent('schedule/AddShiftTemplate'),
    Multiselect,
  },
})
export default class NewShiftWrapper extends Vue {
  popupTypeSelected = 'NewShift'

  created() {

  }

  shiftTabSelected(tab) {
    if (tab === 'NewShift') {
      this.popupTypeSelected = 'NewShift';
    } else {
      this.popupTypeSelected = 'NewShiftTemplate';
    }
  }

  closeAddShiftPopup() {
    this.$emit('close-add-shift-popup');
  }

  closeAddShiftTemplatePopup() {
    this.$emit('close-add-shift-template-popup');
  }
}
